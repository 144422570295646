import React, { useState } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { useFlexSearch } from "react-use-flexsearch"
import * as queryString from "query-string"

const SearchBar = styled.div`
  display: flex;
  border: 1px solid #dfe1e5;
  border-radius: 10px;
  margin: 0 auto 10px;
  width: 100%;
  height: 3rem;
  background: #fdfdfd;

  svg {
    margin: auto 1rem;
    height: 20px;
    width: 20px;
    color: #9aa0a6;
    fill: #9aa0a6;
  }

  input {
    display: flex;
    flex: 100%;
    height: 100%;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, "Helvetica Neue", Arial, sans-serif;
    font-size: 16px;
    background-color: transparent;
    border: none;
    margin: 0;
    padding: 0;
    padding-right: 0.5rem;
    color: rgb(55, 53, 47);
    word-wrap: break-word;
    outline: none;
  }
`

const StyledBlogPosts = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  align-content: center;
  grid-row-gap: 2rem;
  padding: 0 var(--padding-right) 10rem var(--padding-left);
  max-width: var(--max-width);
  margin: 0 auto;
`;

const StyledAllBlogPosts = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 30px;

  @media only screen and (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (max-width: 500px) {
    grid-template-columns: 1fr;
  }
`;

const StyledBlogPost = styled.div`
  border: 1px solid var(--color-background-2);
  padding: 20px;
  box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, .1);

  .blog__image {
    display: block;
    width: calc(100% + 40px);
    margin-bottom: 5px;
    margin-top: -20px;
    margin-left: -20px;
    max-height: 150px;
    overflow: hidden;

    & img {
      width: 100%;
    }
  }

  .blog__title {
    font-size: var(--font-size-body-h2);
    margin-bottom: 5px;

    & a {
      color: var(--color-primary);
      text-decoration: none;
      outline: 0;
    }
  }

  .blog__date {
    font-size: 12px;
  }

  .blog__description {
    margin-top: 10px;
    line-height: 1.5;
  }
`;

const SearchedPosts = ({ results }) =>
  results.length > 0 ? (
    results.map(node => {
      const blogImage = node.image.publicURL;
      const date = node.date;
      const title = node.title || node.slug;
      const description = node.description;
      const excerpt = node.excerpt;
      const slug = node.slug;

      return (
        <StyledBlogPost key={slug}>
          <div className="blog__image">
            <img src={blogImage !== '' && typeof(blogImage) != 'undefined' ? blogImage : "https://via.placeholder.com/200x130&text=up2date.nl"} alt="Nieuwsartikel foto" />
          </div>
          <h3 className="blog__title">
            <Link style={{ boxShadow: `none` }} to={`/blog${slug}`}>
              {title}
            </Link>
          </h3>
          <p className="blog__date">{date}</p>
          <p className="blog__description"
            dangerouslySetInnerHTML={{
              __html: description || excerpt,
            }}
          />
        </StyledBlogPost>
      )
    })
  ) : (
    <p style={{ textAlign: "center" }}>
      Sorry, we kunnen geen nieuwsartikelen vinden die aan uw zoekopdracht voldoen.
    </p>
  )

const AllPosts = ({ posts }) => (
  <StyledAllBlogPosts>
    {posts.map(({ node }) => {
      const title = node.frontmatter.title || node.fields.slug;
      const blogImage = node.frontmatter.image.publicURL;

      return (
        <StyledBlogPost key={node.fields.slug}>
          <div className="blog__image">
            <img src={blogImage !== '' && typeof(blogImage) != 'undefined' ? blogImage : "https://via.placeholder.com/200x130&text=up2date.nl"} alt="Nieuwsartikel foto" />
          </div>
          <h3 className="blog__title">
            <Link style={{ boxShadow: `none` }} to={`/nieuwsartikelen${node.fields.slug}`}>
              {title}
            </Link>
          </h3>
          <p className="blog__date">{node.frontmatter.date}</p>
          <p className="blog__description"
            dangerouslySetInnerHTML={{
              __html: node.frontmatter.description || node.excerpt,
            }}
          />
        </StyledBlogPost>
      )
    })}
  </StyledAllBlogPosts>
)

const SearchPosts = ({ posts, localSearchNieuwsartikelen, location, navigate }) => {
  const { search } = queryString.parse(location.search)
  const [query, setQuery] = useState(search || "")
  
  const results = useFlexSearch(
    query,
    localSearchNieuwsartikelen.index,
    localSearchNieuwsartikelen.store
  )

  return (
    <>
      <StyledBlogPosts>
        <SearchBar>
          <svg
            focusable="false"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5 6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path>
          </svg>
          <input
            id="search"
            type="search"
            placeholder="Zoek in alle nieuwsartikelen"
            value={query}
            onChange={e => {
              navigate(
                e.target.value ? `/nieuwsartikelen/?search=${e.target.value}` : "/nieuwsartikelen/"
              )
              setQuery(e.target.value)
            }}
          />
        </SearchBar>
        {query ? <SearchedPosts results={results} /> : <AllPosts posts={posts} />}
      </StyledBlogPosts>
    </>
  )
}

export default SearchPosts
