// Import libraries
import React from 'react';
import { useStaticQuery, graphql } from "gatsby";
import styled from 'styled-components';


// Import global styles
import GlobalStyle from '../styles/global';


// Import component
import Layout from '../layout/Layout';
import SEO from '../layout/SEO';
import SearchPosts from '../components/searchPosts';


// Component styles
const StyledNewsArticles = styled.div`
    .content {
        display: grid;
        grid-template-rows: repeat(2, max-content);
        align-content: center;
        grid-row-gap: 2rem;
        padding: 10rem var(--padding-right) 10rem var(--padding-left);
        text-align: center;
        max-width: var(--max-width);
        margin: 0 auto;
        
        & h1 {
            font-size: var(--font-size-body-h1);
            font-weight: var(--font-weight-body-h1);
            line-height: var(--line-height-body-h1);
        }

        & p {
            font-size: var(--font-size-body-p);
            font-weight: var(--font-weight-body-p);
            line-height: var(--line-height-body-p);
        }
    }
`;


// Create component
const NieuwsArtikelPagina = ({ navigate, location }) => {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                }
            }

            localSearchNieuwsartikelen {
                index
                store
            }

            allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
                edges {
                    node {
                        excerpt
                        fields {
                            slug
                        }
                        frontmatter {
                            date(formatString: "MMMM DD, YYYY")
                            image {
                                publicURL
                            }
                            title
                            description
                        }
                    }
                }
            }
        }
    `);

    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMdx.edges
    const localSearchNieuwsartikelen = data.localSearchNieuwsartikelen

    return (
        <React.Fragment>
            <GlobalStyle />
            <SEO
                title="All Nieuwsartikelen"
                description="Alle up2date nieuwsartikelen."
            />
            <Layout location={location} title={siteTitle}>
                <StyledNewsArticles>
                    <div className="content">
                        <h1>Alle Nieuwsartikelen</h1>
                        <p>Al onze nieuwsartikelen kunt u hier vinden.</p>
                    </div>

                    <SearchPosts
                        posts={posts}
                        localSearchNieuwsartikelen={localSearchNieuwsartikelen}
                        navigate={navigate}
                        location={location}
                    />
                </StyledNewsArticles>
            </Layout>
        </React.Fragment>
    );
};


// Export component by default
export default NieuwsArtikelPagina;